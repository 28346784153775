//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SideNavbar from '~/components/admin/navbars/SideNavbar.vue'

export default {
  components: {
    SideNavbar
  }
}
