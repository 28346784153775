//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      menu: [
        {
          name: 'Kilépés',
          callback: this.logout
        }
      ]
    }
  }
}
