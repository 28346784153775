//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavBar from '~/components/navbar'
import MyFooter from '~/components/footer'

export default {
  components: {
    NavBar, MyFooter
  }
}
