

export default async function (session) {
  await import('firebase/functions')

  const functionsService = session.functions('us-central1')

  functionsService.useFunctionsEmulator('http://localhost:5000')

  return functionsService
}
