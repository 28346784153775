import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cbbcc56c = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _0335fa89 = () => interopDefault(import('../pages/belteri-beton-elemek/index.vue' /* webpackChunkName: "pages/belteri-beton-elemek/index" */))
const _f3ab03a2 = () => interopDefault(import('../pages/beton-grill-konyha/index.vue' /* webpackChunkName: "pages/beton-grill-konyha/index" */))
const _67af40d6 = () => interopDefault(import('../pages/beton-viraglada/index.vue' /* webpackChunkName: "pages/beton-viraglada/index" */))
const _7074b92a = () => interopDefault(import('../pages/furdoszobai-beton-elemek/index.vue' /* webpackChunkName: "pages/furdoszobai-beton-elemek/index" */))
const _66519080 = () => interopDefault(import('../pages/grill-shop/index.vue' /* webpackChunkName: "pages/grill-shop/index" */))
const _eac73456 = () => interopDefault(import('../pages/kapcsolat/index.vue' /* webpackChunkName: "pages/kapcsolat/index" */))
const _ef45eea6 = () => interopDefault(import('../pages/kerti-beton-elemek/index.vue' /* webpackChunkName: "pages/kerti-beton-elemek/index" */))
const _8eb7d916 = () => interopDefault(import('../pages/kulteri-grill-konyha/index.vue' /* webpackChunkName: "pages/kulteri-grill-konyha/index" */))
const _34436490 = () => interopDefault(import('../pages/latvanyterv-keszites/index.vue' /* webpackChunkName: "pages/latvanyterv-keszites/index" */))
const _ffd8db26 = () => interopDefault(import('../pages/szinek/index.vue' /* webpackChunkName: "pages/szinek/index" */))
const _72d2aeec = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _00556d68 = () => interopDefault(import('../pages/admin/editor/index.vue' /* webpackChunkName: "pages/admin/editor/index" */))
const _56e23a24 = () => interopDefault(import('../pages/admin/login/index.vue' /* webpackChunkName: "pages/admin/login/index" */))
const _2514240a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _cbbcc56c,
    name: "admin"
  }, {
    path: "/belteri-beton-elemek",
    component: _0335fa89,
    name: "belteri-beton-elemek"
  }, {
    path: "/beton-grill-konyha",
    component: _f3ab03a2,
    name: "beton-grill-konyha"
  }, {
    path: "/beton-viraglada",
    component: _67af40d6,
    name: "beton-viraglada"
  }, {
    path: "/furdoszobai-beton-elemek",
    component: _7074b92a,
    name: "furdoszobai-beton-elemek"
  }, {
    path: "/grill-shop",
    component: _66519080,
    name: "grill-shop"
  }, {
    path: "/kapcsolat",
    component: _eac73456,
    name: "kapcsolat"
  }, {
    path: "/kerti-beton-elemek",
    component: _ef45eea6,
    name: "kerti-beton-elemek"
  }, {
    path: "/kulteri-grill-konyha",
    component: _8eb7d916,
    name: "kulteri-grill-konyha"
  }, {
    path: "/latvanyterv-keszites",
    component: _34436490,
    name: "latvanyterv-keszites"
  }, {
    path: "/szinek",
    component: _ffd8db26,
    name: "szinek"
  }, {
    path: "/test",
    component: _72d2aeec,
    name: "test"
  }, {
    path: "/admin/editor",
    component: _00556d68,
    name: "admin-editor"
  }, {
    path: "/admin/login",
    component: _56e23a24,
    name: "admin-login"
  }, {
    path: "/",
    component: _2514240a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
