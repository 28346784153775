import Vue from 'vue'

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__user_mixin__) {
  Vue.__user_mixin__ = true
  Vue.mixin({
    methods: {
      login (email, password) {
        this.$fire.auth.signInWithEmailAndPassword(email, password)
          .then(async (userCredential) => {
            await this.$store.dispatch('user/saveFirebaseLogin', userCredential.user)
            // TODO succesful operation window/popup
            if (!this.isAdmin()) {
              console.log('Error not admin, redirecting to the main site')
              this.$router.push('/')
            } else {
              console.log('Succesful login')
              this.$router.push('/admin')
            }
          })
          .catch((error) => {
            console.log(error)
            // const errorCode = error.code
            // const errorMessage = error.message
          })
      },
      logout () {
        this.$fire.auth.signOut().then(() => {
          this.setUser(null)
          console.log('Succesful logout')
          this.$router.push('/')
          // Sign-out successful.
        }).catch((error) => {
          console.log(error)
          // An error happened.
        })
      },
      setUser (user) {
        this.$store.commit('user/setUser', user)
      },
      isLoggedIn () {
        return this.$store.getters['user/isLoggedIn']
      },
      isAdmin () {
        return this.isLoggedIn() && this.$store.getters['user/isAdmin']
      },
      isRole (role) {
        return this.$store.getters['user/isRole'](role)
      },
      makeAuthenticatedFirebaseRequest (method, endpoint, data = [], headers = []) {
        return new Promise((resolve, reject) => {
          this.$fire.auth.currentUser.getIdToken(true).then((token) => {
            console.log('Sending request to', endpoint, 'with ID token in Authorization header.')

            resolve(this.$axios[method](process.env.FIREBASE_FUNCTIONS_URL + endpoint, data, {
              headers: {
                authorization: 'Bearer ' + token
              }
            }))
          }).catch((error) => {
            reject(error)
          })
        })
      }
    }
  })
}
