export const state = () => {
  return {
    user: null
  }
}

export const actions = {
  init (store, { res }) {
    let authUser = null
    if (process.server) {
      if (res && res.locals && res.locals.user) {
        authUser = res.locals.user
      }
    }
    if (!process.server) {
      authUser = this.$fire.auth.currentUser
    }
    if (authUser) {
      store.dispatch('saveFirebaseLogin', authUser)
    }
  },
  async onAuthStateChanged (store, { authUser, claims }) {
    if (authUser) {
      await store.dispatch('saveFirebaseLogin', {
        uid: authUser.uid,
        email: authUser.email,
        allClaims: claims
      })
    } else {
      store.commit('setUser', null)
    }
  },
  async saveFirebaseLogin (store, user) {
    // console.log(user)
    let claims = null
    if (process.server) {
      claims = user.allClaims
    } else {
      const idTokenResult = await this.$fire.auth.currentUser.getIdTokenResult()
      // console.log(idTokenResult)
      claims = idTokenResult.claims
    }
    store.commit('setUser', {
      uid: user.uid,
      email: user.email,
      claims
    })
  }
}

export const mutations = {
  setUser (state, user) {
    state.user = user
  }
}

export const getters = {
  loadUser: state => async (uid) => {
    // await this.$fire.firestore.collection('users').doc(uid).get()
  },
  getUser: (state) => {
    return state.user
  },
  isLoggedIn: (state) => {
    return state.user !== null
  },
  isAdmin: (state) => {
    return state.user && state.user.claims.admin
  },
  isRole: (state) => {
    return (role) => {
      return state.user && state.user.claims[role]
    }
  }
}
