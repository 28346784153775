import Vue from 'vue'

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.
if (!Vue.__site_mixin__) {
  Vue.__site_mixin__ = true
  Vue.mixin({
    methods: {
      __ (id, fallback = null) {
        // console.log(this.$route)
        const result = this.$store.getters['site/__'](id, this.$route.path)
        return result || (fallback || id)
      }
    }
  })
}
