export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Box = () => import('../../components/box.vue' /* webpackChunkName: "components/box" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const ImgWithTextRow = () => import('../../components/imgWithTextRow.vue' /* webpackChunkName: "components/img-with-text-row" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const OthersSection = () => import('../../components/othersSection.vue' /* webpackChunkName: "components/others-section" */).then(c => wrapFunctional(c.default || c))
export const Promo = () => import('../../components/promo.vue' /* webpackChunkName: "components/promo" */).then(c => wrapFunctional(c.default || c))
export const ShopSection = () => import('../../components/shopSection.vue' /* webpackChunkName: "components/shop-section" */).then(c => wrapFunctional(c.default || c))
export const ModalsEditorModal = () => import('../../components/modals/editor-modal.vue' /* webpackChunkName: "components/modals-editor-modal" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonPagination = () => import('../../components/admin/common/pagination.vue' /* webpackChunkName: "components/admin-common-pagination" */).then(c => wrapFunctional(c.default || c))
export const AdminNavbarsSideNavbar = () => import('../../components/admin/navbars/SideNavbar.vue' /* webpackChunkName: "components/admin-navbars-side-navbar" */).then(c => wrapFunctional(c.default || c))
export const AdminPageBase = () => import('../../components/admin/page/PageBase.vue' /* webpackChunkName: "components/admin-page-base" */).then(c => wrapFunctional(c.default || c))
export const AdminPageHeader = () => import('../../components/admin/page/PageHeader.vue' /* webpackChunkName: "components/admin-page-header" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReCheckbox = () => import('../../components/rxt/reform/ReCheckbox.vue' /* webpackChunkName: "components/rxt-reform-re-checkbox" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReClock = () => import('../../components/rxt/reform/ReClock.vue' /* webpackChunkName: "components/rxt-reform-re-clock" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReDateRange = () => import('../../components/rxt/reform/ReDateRange.vue' /* webpackChunkName: "components/rxt-reform-re-date-range" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReDraggable = () => import('../../components/rxt/reform/ReDraggable.vue' /* webpackChunkName: "components/rxt-reform-re-draggable" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReInput = () => import('../../components/rxt/reform/ReInput.vue' /* webpackChunkName: "components/rxt-reform-re-input" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReSelect = () => import('../../components/rxt/reform/ReSelect.vue' /* webpackChunkName: "components/rxt-reform-re-select" */).then(c => wrapFunctional(c.default || c))
export const RxtReformReTextarea = () => import('../../components/rxt/reform/ReTextarea.vue' /* webpackChunkName: "components/rxt-reform-re-textarea" */).then(c => wrapFunctional(c.default || c))
export const RxtRecomponentReOverlayLoader = () => import('../../components/rxt/recomponent/ReOverlayLoader.vue' /* webpackChunkName: "components/rxt-recomponent-re-overlay-loader" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonFormRxtInput = () => import('../../components/admin/common/form/RxtInput.vue' /* webpackChunkName: "components/admin-common-form-rxt-input" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonFormRxtTextarea = () => import('../../components/admin/common/form/RxtTextarea.vue' /* webpackChunkName: "components/admin-common-form-rxt-textarea" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonUtilitiesRxtCalendar = () => import('../../components/admin/common/utilities/RxtCalendar.vue' /* webpackChunkName: "components/admin-common-utilities-rxt-calendar" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonUtilitiesRxtModal = () => import('../../components/admin/common/utilities/RxtModal.vue' /* webpackChunkName: "components/admin-common-utilities-rxt-modal" */).then(c => wrapFunctional(c.default || c))
export const AdminCommonUtilitiesRxtTable = () => import('../../components/admin/common/utilities/RxtTable.vue' /* webpackChunkName: "components/admin-common-utilities-rxt-table" */).then(c => wrapFunctional(c.default || c))
export const RxtReformPartialsCalendarMonth = () => import('../../components/rxt/reform/partials/CalendarMonth.vue' /* webpackChunkName: "components/rxt-reform-partials-calendar-month" */).then(c => wrapFunctional(c.default || c))
export const RxtReformPartialsDatePicker = () => import('../../components/rxt/reform/partials/DatePicker.vue' /* webpackChunkName: "components/rxt-reform-partials-date-picker" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
