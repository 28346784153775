//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      menuOpened: false,
      menuItems: [
        { name: 'Főoldal', url: '/' },
        { name: 'Grill Shop', url: '/grill-shop' },
        { name: 'Kültéri grill konyha', url: '/kulteri-grill-konyha' },
        { name: 'Fürdőszoba', url: '/furdoszobai-beton-elemek' },
        { name: 'Beltér', url: '/belteri-beton-elemek' },
        { name: 'Kert & Virágláda', url: '/beton-viraglada' },
        { name: 'Színek', url: '/szinek' },
        { name: 'Látványterv', url: '/latvanyterv-keszites' },
        { name: 'Kapcsolat', url: '/kapcsolat' }
      ]
    }
  },
  beforeMount () {
    if (process.browser) {
      window.addEventListener('click', this.close)
    }
  },
  beforeDestroy () {
    if (process.browser) {
      window.removeEventListener('click', this.close)
    }
  },
  methods: {
    close (e) {
      // if (!this.$el.contains(e.target) || e.target.tagName !== 'A') {
      //   this.menuOpen = false
      // } else
      if (this.$el.contains(e.target) && (e.target.tagName === 'A' || e.target.tagName === 'IMG')) {
        this.menuOpened = false
      }
    }
  }
}
